@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}
html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--white);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}


img{
    width: 100%;
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
    
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
    
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}


.textGrey{
    color: var(--textGrey);
}
.white{
    color: var(--white);
}
.darkBlue{
    color: var(--darkBlue);
}
.themeColor{
    color: var(--themeColor);
}
.gradientBlue{
        background: var(--gradientBlue);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.gradientGrey{
        background: var(--gradientGrey);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}

.bg-textGrey{
    background: var(--textGrey);
}
.bg-white{
    background: var(--white);
}
.bg-darkBlue{
    background: var(--darkBlue);
}
.bg-themeColor{
    background: var(--themeColor);
}
.bg-gradientBlue{
    background: var(--gradientBlue);
}
.bg-gradientGrey{
    background: var(--gradientGrey);
}
.sticky{
    background: var(--darkBlue);
    box-shadow: 0px 0px 3px #0b4c84ce;
}

.common-space{
    padding: 55px 0;
}

.nav-bar-list{
    display:flex;  
    align-items: center;
    gap: 35px;
   
}

.nav-bar-list a:hover{
    color:var(--themeColor) !important;
    border-bottom: 2px solid var(--themeColor);
}
.active{
    color:var(--themeColor) !important;
    border-bottom: 2px solid var(--themeColor);
    font-family: var(--medium);
    font-weight: 600;
}

.banner-bg{
    width:100%;
    min-height: 660px;
    height:100%;
    background: url('../images/png/banner-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    text-transform: uppercase;
    
}
.about-bg{
    width:100%;
    background: url('../images/png/about-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%; 
}
.about-card{
    width:100%;
    background: url('../images/png/about-bg2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 16px;
}
.footer-bg{
    width:100%;
    // height: 270px;
    background: url('../images/png/footer-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}
.main-heading{
    position: relative;
}
.main-heading::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 4px;
    background: var(--gradientBlue); 
}
.main-heading.stripCenter::before{
    left: 0;
    right: 0;
    margin: auto;
}

.about-card-text{
    position: relative;
    padding: 70px 100px 80px 100px;
}
.about-card-img {
    position: absolute;
    top: -35px;
    right: 100px;
}

.serviceSliderWapper > .slick-slider> .slick-list > .slick-track {
    background: url('../images/png/service-slide-bg2.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}
.serviceSliderWapper> .slick-slider> .slick-list > .slick-track>div:nth-child(even) {
    background: url('../images/png/service-slide-bg1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

.serviceSliderWapper .slick-next {
    right: -60px !important;
}
.serviceSliderWapper .slick-prev {
    left: -60px !important;
}
.serviceSliderWapper .slick-prev, .serviceSliderWapper .slick-next {
    width: 40px !important;
    height: 40px !important;
}

.tabNavBtn:hover, .tabNavBtn:focus{
    color: var(--themeColor);
    
}
.tabNavBtn:hover::before , .tabNavBtn:focus:before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 3px;
    background: var(--themeColor);
}

.brand-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width:100%;
    background: url('../images/png/brand-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 16px;
    position: relative;
    padding:50px;
    margin-bottom: 25px;
    margin-top:110px ;
    
}
.brand-card .slick-list{
    width: 100% !important;
}
.brand-card  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex !important;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
}
.textarea-box{
    position: relative;
    box-shadow: 3px 3px 10px #e3e3e3e0;
    -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 8px;
}
.box-model{
 position: relative;
 box-shadow: 3px 3px 10px #e3e3e3e0;
-webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
-moz-box-shadow: 3px 3px 10px #e3e3e3e0;
border-radius: 8px;
}
.textarea-box> textarea{
    padding: 10px 10px 10px 35px;
    // box-shadow: 3px 3px 10px #e3e3e3e0;
    // -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    // -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 8px;
    background: var(--white);
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}
.box-model > textarea{
    padding: 10px 10px 10px 15px;
    // box-shadow: 3px 3px 10px #e3e3e3e0;
    // -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    // -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 8px;
    background: var(--white);
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}
.textarea-box> textarea::placeholder{
    color: var(--textPalceholder);
}
.box-model> textarea::placeholder{
    color: var(--textPalceholder);
}
.text-icon{
    position: absolute;
    top: 10px;
    left: 10px;
}
.sub-heading{
    position: relative;
    top: 60px;
    left: 4px;
}
.Main-heading{
    position: relative;
    top: 35px;
}
.toggleBtn{
    display: none;
}
// .about-img{
//     width: 100%;
// }
.Footer-social-links{
    display: flex;
    align-items: center;
    gap: 20px;
}
.error-message{
    padding: 4px 6px 0 6px;
    margin: 0px 0;
    color: red !important;
    font-size: 13px !important;
}
input{
    color: var(--textGrey) !important;
}
.about-arabic{
    display:none;
}
.SlideBox .gradientGrey{
    width: 47%;
    padding: 0 10px;
}
.copyRightRes{
    display: none;
}
.videoContainer{
    background-image: url(../images/jpg/videoBg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
/***************** RTL DIrection*************************/

.rtl .nav-bar-list a{
    font-family: var(--arBold);
}
.rtl .footer-list a{
    font-family: var(--arBold);
}
.rtl .tabNavBtn{
    font-family: var(--arBold);
}
.rtl input::placeholder{
    font-family: var(--arMedium);
}
.rtl textarea::placeholder{
    font-family: var(--arMedium);
}
.rtl .footer-list {
    gap: 12px !important;
}
.rtl .about-arabic{
    display:block;
}
.rtl .about-English{
    display:none;
}
.rtl .SlideBox img{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);

}

.rtl .about-card-img {
    right: auto;
    left: 130px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.rtl svg{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    right: 0;
    left: auto;
    margin: 0 8px;
}
.location-card svg{
    margin-right: 8px;
}
.rtl .location-card svg{
    margin-right: 0;
}
.rtl svg.slick-arrow {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
}
.rtl .parts-vectior img{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.rtl .footer-vector-img{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.rtl .contact-vector-img{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.rtl .brand-vector img{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

// .rtl .banner-card img{
//     -webkit-transform: scaleX(-1) !important;
//     -moz-transform: scaleX(-1) !important;
//     -o-transform: scaleX(-1) !important;
//     transform: scaleX(-1) !important;
// }
.rtl .text-icon{
    left: auto;
    -webkit-transform: scaleX(-1)!important;
    -moz-transform: scaleX(-1)!important;
    -o-transform: scaleX(-1)!important;
    transform: scaleX(-1)!important;
}
.rtl .about-bg .custom-img{
    -webkit-transform: scaleX(1)!important;
    -moz-transform: scaleX(1)!important;
    -o-transform: scaleX(1)!important;
    transform: scaleX(1) !important;
}
.rtl .banner-bg {
    -webkit-transform: scaleX(-1)!important;
    -moz-transform: scaleX(-1!important);
    -o-transform: scaleX(-1)!important;
    transform: scaleX(-1) !important;
}
.rtl .banner-card{
    transform: matrix(-1, 0, 0, 1, 0, 0) !important ;
}
.rtl .location-card p{
    direction: ltr !important;
}
.rtl .nav-bar button >span{
    direction: ltr !important;
}
.rtl .logo svg {
    transform: scale(1);
}

.rtl .location-card p{
    text-align: right;
}

.rtl .footer-vector-img {
    width: 180px !important;
}

.rtl .main-heading::before{
    left: auto;
}

.rtl .serviceSliderWapper .slick-prev {
    left: -60px !important;
    right: auto !important;
}
.rtl .serviceSliderWapper .slick-next {
    right: -60px !important;
    left: auto !important;
}
.rtl .slick-prev, .rtl .slick-next {
    top: calc(50% - 20px);
}
.rtl #brands .slick-prev, .rtl #brands .slick-next {
    top: calc(50% - 10px);
}

.rtl input{
    padding-right: 35px;
    padding-left: 10px;
}

.rtl .textarea-box> textarea{
    padding: 10px 35px 10px 10px;
}

.rtl [dir='ltr'] .slick-slide {
    float: left !important;
    direction: rtl !important;
}
.rtl .banner-card .sub-heading {
    letter-spacing: 0px;
    position: relative;
    top: 45px !important;
    left: 0px !important;
}

.rtl .main-heading::before {
    content: '';
    position: absolute;
    left: auto;
    right: 0;
    width: 70px;
    height: 4px;
    background: var(--gradientBlue);
    bottom: -5px;
}
.rtl .main-heading.stripCenter::before{
    left: 0;
    right: 0;
    margin: auto;
}
// .rtl .brand-card .main-heading::before {
//     left: 90px !important;
//     right: auto;
   
// }
// .rtl .contact-card .main-heading::before {
//     left: 50px !important;
//     right: auto;
   
// }
.rtl .subHeading {
    
    top: -20px !important;
}
.rtl .modal-header .btn-close {
    margin:0  !important;
    padding:0  !important;
}
.rtl .Footer-social-links svg{
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(1) !important;
}
.rtl .brand-card .slick-prev {
    left: -35px !important;
    right: auto;
}
.rtl .brand-card .slick-next {
    right: -35px !important;
    left: auto;
}
.rtl .whatsapp {
    position: fixed;
    bottom: 15px;
    right: 0px !important;
    left: auto !important;
    z-index: 1;
}
.rtl .change-Btn {
    position: fixed;
    bottom: 0;
    right: auto !important;
    left: 0 !important;
}

/***************** responsive file*************************/


@import 'responsive';
 