@media(max-width:1399px) {
     
}
@media(max-width:1199px) {
   .subHeading {
        top: -8px !important;
    }
    .about-card-img {
        right: 30px !important;
    }
    .sub-heading {
        top: 50px !important;
    }
    .Main-heading {
        position: relative;
        top: 30px;
    }
    .resp{
        font-size: 46px !important;
    }
    .parts-sep {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
    .Footer-social-links{
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media(max-width:991px) {
    .banner-card {
        flex-wrap: wrap;
        gap: 20px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .banner-text-card{
        padding-left: 0;
        padding-right: 0;
    }
    .common-space {
        padding: 40px 0;
    }
    #about{
        padding-bottom: 20px;
    }
    .nav-bar-list{
        display: none;
    }
    .toggleBtn {
        display: block;
    }
    .toggleBtn svg{
        color: var(--white);
    }
    .showToggle{
        position: absolute;
        top: 80px;
        left: 0;
        background: var(--darkBlue);
        color: var(--white);
        width: 100%;
        display: block;
        box-shadow: 1px 0px 15px #0d64b1b0;
    }
    .showToggle div{
        border-bottom: 1px solid var(--white);
        outline: none;
        width: 100%;
        // padding: 20px 10px;
    }
    .showToggle a{
        padding: 15px 10px;
        display: flex;
        width: 100%;
    }
    .showToggle a :hover{
        color: var(--themeColor);
        
    }
    .showToggle .active{
        color: var(--themeColor);
    }
    .nav-bar-list a:hover {
        border-bottom: none;
    }
    .nav-bar-list a.active {
        border-bottom: none;
    }
    .about-img{
        text-align: center;
    }
    .about-text-card .subHeading {
        position: absolute;
        top: -5px;
        letter-spacing: 0.5px;
    }
    .about-card-text {
        padding: 40px 20px 50px 20px;
    }
    .parts-sep {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
    .footer-list {
        gap: 15px !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
    }
    .about-card-img {
        right: 10px !important;
        width: 35% !important;
        top: 20px !important;
    }
    .Footer-social-links{
        justify-content: center;
    }
    .Footer-social-links>div{
        justify-content: center;
        align-items: center;
    }
    .QR-code-text >span{
        font-size: 14px !important;
    }
    //  .SlideBox {
    //     flex-wrap: wrap !important;
    // }
    .footer-vector-img{
        width: 150px ;
    }
    .footer-vector {
        top: -60px !important;
    }
    .container-fluid{
        padding-left: 0;
        padding-right: 0;
    }
    
}
@media(max-width:768px) {
    
    .banner-text-card{
        text-align: center !important;
        align-items: center;
    }
    // .content-btn{
    //     justify-content: center !important;
    // }
    .about-card-text .gradientGrey{
        font-size: 30px !important;
    }
    .about-card-text p{
        font-size: 14px !important;
    }
    // .SlideBox {
    //     flex-wrap: wrap !important;
    // }
    .vedio-card span{
        font-size: 27px !important;
    }
    .parts-vectior img{
        width: 170px !important;
    }
    .parts-sep {
        grid-template-columns: 1fr 1fr !important;
    }
     .brand-vector {
        top: -90px !important;
        right: 0px;
        width: 100%;
    }
     .brand-vector img {
        width: 90% !important;
    }
    .main-heading{
        font-size: 40px !important;
    }
    #services >div >div>div {
        font-size: 42px !important;
      
      }
    .contact-vector-img{
        width: 60px !important;
    }
    .footer-vector-img{
        width: 160px !important;
    }
    .about-card-img {
        top: 50px !important;
    }
    .about-card-text {
        padding: 30px 20px 40px 20px !important;
    }
    
    .about-text-card .subHeading {
        letter-spacing: 0px !important;
        font-size: 13px !important;
    }
    // .about-text-card .content-btn {
    //     justify-content: left !important;
    // }
    #services{
        padding-top: 0px;
    }
    
    .serviceSliderWapper .slick-prev {
        position: absolute;
        left: calc(50% - 50px) !important;
        z-index: 1;
        top: 230px;
        
    }
    
    .serviceSliderWapper .slick-next {
        right: calc(50% - 50px) !important;
        position: absolute;
        z-index: 1;
        top: 230px;
        
    }
    .brandFluid{
        padding-top: 110px;
    }
    .subHeading {
        top: -12px !important;
    }
    .partNav{
        flex-wrap: wrap !important;
        justify-content: center !important;
        gap: 20px !important;
    }
    .qr-code{
        flex-wrap: wrap-reverse;
        align-items: center;
        justify-content: center;
    }
    .footer-container{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    .footer-container>div{
        margin: auto;
    }
    .Footer-social-links >div{
        gap: 10px;
    }
    .Footer-social-links {
        flex-wrap: wrap;
        gap: 20px !important;
        margin-bottom: 15px !important;
    }
    .videoContainer{
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
    .location-card{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .copyRightDes{
        display: none;
    }
    .copyRightRes{
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 12px !important;
        margin-top: 20px;
    }
}
@media(max-width:767px) {
    .vatTxt{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}
@media(max-eidth:680px){
    // .serviceSliderWapper .slick-prev {
    //     position: absolute;
    //     left: 180px !important;
    //     z-index: 1;
    //     top: 230px;
    // }
    
    // .serviceSliderWapper .slick-next {
    //     right: 180px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 230px;
        
    // }
}
@media (max-width: 576px){
    
    .subHeading {
        font-size: 15px !important;
        top: -10px !important;
    }
    .parts-vectior img {
        width: 130px !important;
    }
    .parts-vectior {
        top: 0px !important;
    }
    .serviceSliderWapper .slick-prev, .serviceSliderWapper .slick-next {
        width: 30px !important;
        height: 30px !important;
        top: 230px;
        
    }
    .serviceSliderWapper .slick-prev {
        left: calc(50% - 40px) !important;
        
    }
    
    .serviceSliderWapper .slick-next {
        right: calc(50% - 40px) !important;
    }
    // .serviceSliderWapper .slick-prev {
    //     position: absolute;
    //     left: 210px !important;
    //     z-index: 1;
    //     top: 230px;
    // }
    // .serviceSliderWapper .slick-next {
    //     position: absolute;
    //     right: 210px !important;
    //     z-index: 1;
    //     top: 230px;
    // }
    .about-text-card .subHeading {
        // left: 162px !important;
        font-size: 15px !important;
    }
    .resp {
        font-size: 40px !important;
    }
    .Main-heading {
        position: relative;
        top: 25px;
    }
    .sub-heading {
        top: 45px !important;
        font-size: 15px !important;
        letter-spacing: 1px !important;
    }
    .partNav {
        flex-wrap: wrap !important;
        justify-content: center !important;
    }
    .tabNavBtn:hover::before, .tabNavBtn:focus:before {
        position: absolute;
        bottom: 0px !important;
        width: 93% !important;
        height: 2px !important;
    }
    .banner-card>div{
        margin-bottom: 30px;
    }
    .footer-vector-img {
        width: 100px !important;
    }
    .content-heading:before {
        top: 30px !important;
    }
    .logo svg {
        width: 100px;
    }
    .subHeading {
        font-size: 15px !important;
        top: -6px !important;
    }
    .brand-quality {
        grid-template-columns: 1fr !important;
    }
    #about {
        padding-bottom: 0px;
    }
    .brandFluid{
        padding-top: 90px;
    }
    .footer-vector {
        top: -40px !important;
    }
    .qr-code{
        flex-wrap: wrap;
    }
    .QR-code-text{
        width: 100%;
        align-items: center !important;
        gap: 5px;
        margin-top: 10px;
        flex-wrap: wrap;
        text-align: center;
    }
    .QR-code-text span{
        width: 100%;
        text-align: center;
    }
    .scrollDown{
        width: 45px;
        height: 45px;
    }
}

@media (max-width: 480px){
    .about-text-card .subHeading {
        font-size: 13px !important;
    }
    .main-heading {
        font-size: 33px !important;
    }
    .about-card-img {
        top: 80px !important;
    }
    #services >div >div>div {
      font-size: 36px !important;
    
    }
    .partsHeading span{
        font-size: 15px;
    }
    // .serviceSliderWapper .slick-prev {
    //     left: 160px !important;
    //     z-index: 1;
    // }
    // .serviceSliderWapper .slick-next {
    //     right: 160px !important;
    // }
    .parts-sep {
        grid-template-columns: 1fr !important;
    }
    .parts-vectior {
        top: -50px !important;
    }
    // .brand-card .main-heading:before {
    //     left: 90px !important;
    // }
    // .contact-card .main-heading:before {
    //     left: 90px !important;
    // }
    .QR-code-text {
        display: flex;
         flex-direction: row !important; 
        gap: 5px !important;
    }
    .vedio-card span {
        font-size: 18px !important;
    }
    .subHeading {
        font-size: 15px !important;
        top: -10px !important;
    }
    .headerWhatsapp{
        display: none !important;
    }
}

@media (max-width: 430px){
    .resp{
    font-size: 35px !important;
    }
    .sub-heading {
    top: 35px !important;
    }
    .Main-heading {
        position: relative;
        top: 25px;
    }
    .about-card-text .gradientGrey {
    font-size: 25px !important;
    }
    .top-brand .main-heading::before { 
        left: 0px !important;
        width: 65px !important;
        bottom: 0px !important;
    }
    #services >div >div>div {
        font-size: 32px !important;
      }
    .main-heading {
        font-size: 32px !important;
    }
    .main-heading::before {
        left: 0px !important;
        width: 60px !important;
    }
    // .contact-card .main-heading::before {
    //     left: 90px !important;
    //     width: 70px !important;
    // }
    .footer-vector {
        top: -30px !important;
    }
    .footer-list {
        gap: 20px !important;
    }
    .subHeading {
        font-size: 15px !important;
        top: -10px !important;
    }
    // .serviceSliderWapper .slick-prev {
    //     left: 145px !important;
    //     z-index: 1;
    // }
    // .serviceSliderWapper .slick-next {
    //     right: 145px !important;
    // }


   
}

@media (max-width: 400px){
    .subHeading {
        top: -13px !important;
    }

    .resp {
        font-size: 30px !important;
    }
    .sub-heading {
        top: 28px !important;

    }
    .Main-heading {
        top: 16px !important;
    }
    p{
        font-size: 14px !important;
    }
    .about-text-card .subHeading {
        font-size: 13px !important;
    }
    .about-card-text .gradientGrey {
        font-size: 22px !important;
    }
    .about-card-text p {
        font-size: 12px !important;
    }
    button>span{
        font-size: 12px !important;
    }
    .parts-vectior img {
        width: 90px !important;
    }
    .brand-vector {
        top: -59px !important;
        left: 100px !important;
        width: 58% !important;
    }
    .contact-vector-img {
        width: 40px !important;
    }
    .main-heading {
        font-size: 26px !important;
    }
    // .partsHeading .main-heading:before {
    //     left: 50px !important;
    // }
    // .brand-card .main-heading:before {
    //     left: 50px !important;
    // }
    // .contact-card .main-heading:before {
    //     left: 75px !important;
    // }
    .content-heading:before {
        top: 23px !important;
    }
    #services >div >div>div {
        font-size: 26px !important;
      
    }
    .top-brand.main-heading::before {
        content: "";
        left: 120px !important;
        width: 52px !important;
    }
    .brand-card{
        margin-top: 70px;
    }
    // .serviceSliderWapper .slick-prev {
    //     left: 130px !important;
    //     z-index: 1;
    // }
    // .serviceSliderWapper .slick-next {
    //     right: 130px !important;
    // }
}
   
    
    




    /***************** RTL DIrection*************************/

@media(max-width:1199px) {

   .rtl .subHeading {
        top: -10px !important;
    }
    .rtl .about-card-img {
        left: 30px !important;
        width: 36% !important;
        right: auto !important;
    }
    .rtl .sub-heading {
        top: 50px !important;
    }
    .rtl .Main-heading {
        position: relative;
        top: 30px;
    }
    .rtl .resp{
        font-size: 46px !important;
    }
    .rtl .parts-sep {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
}
@media (max-width: 991px){
   .rtl .parts-sep {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
    .banner-card {
        padding-top: 10px !important;
    }
}
@media (max-width: 768px){
   .rtl .parts-sep {
        grid-template-columns: 1fr 1fr !important;
    }
    .rtl .about-text-card .subHeading {
        font-size: 13px !important;
    }
    .rtl .Main-heading {
        top: 20px !important;
    }
    .rtl .banner-card .sub-heading {
        top: 30px !important;
        left: 0px !important;
    }
    // .rtl #services .main-heading::before {
    //     left: auto !important;
    //     right: 0px !important;
    // }
    // .rtl .top-brand .main-heading::before {
    //     left: auto !important;
    //     right: 200px !important;
    // }
    .rtl .top-brand .subHeading {
        top: -20px !important;
    }
    .rtl .partsHeading span {
        top: 10px !important;
    }
    // .rtl .serviceSliderWapper .slick-next {
    //     left: 230px !important;
    //     right: auto !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    // .rtl .serviceSliderWapper .slick-prev {
    //     left: auto !important;
    //     right: 230px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    
    .rtl .serviceSliderWapper .slick-prev {
        left: calc(50% - 50px) !important;
        right: auto !important;
        top: 220px;
    }
    
    .rtl .serviceSliderWapper .slick-next {
        right: calc(50% - 50px) !important;
        left: auto !important;
        top: 220px;
    }
    
}
@media (max-width: 576px){
    .rtl .parts-sep {
         grid-template-columns: 1fr !important;
     }
     .rtl .serviceSliderWapper .slick-prev {
        left: calc(50% - 48px) !important;
        
    }
    
    .rtl .serviceSliderWapper .slick-next {
        right: calc(50% - 48px) !important;
        
    }
    //  .rtl .serviceSliderWapper .slick-next {
    //     left: 210px !important;
    //     right: auto !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    // .rtl .serviceSliderWapper .slick-prev {
    //     left: auto !important;
    //     right: 210px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    .rtl .footer-vector-img {
        width: 100px !important;
    }
    .rtl .footer-vector {
        top: -30px !important;
    }
    // .rtl .SlideBox {
    //     flex-wrap: wrap !important;
    // }
    // .rtl .QR-code-text {
    //     gap: 35px !important;
    // }
    .rtl .logo svg {
        width: 100px;
    }
    
 }
 @media (max-width: 480px){

    // .rtl .serviceSliderWapper .slick-next {
    //     left: 160px !important;
    //     right: auto !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    // .rtl .serviceSliderWapper .slick-prev {
    //     left: auto !important;
    //     right: 160px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    
 
 }
 @media (max-width: 420px){

    // .rtl .serviceSliderWapper .slick-next {
    //     left: 120px !important;
    //     right: auto !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    // .rtl .serviceSliderWapper .slick-prev {
    //     left: auto !important;
    //     right: 120px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
 
 }
 @media (max-width: 390px){

    .rtl .logo svg {
        width: 85px;
    }
    .nav-bar  div{
        gap: 0 !important;
    }
    // .rtl .serviceSliderWapper .slick-next {
    //     left: 100px !important;
    //     right: auto !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
    // .rtl .serviceSliderWapper .slick-prev {
    //     left: auto !important;
    //     right: 100px !important;
    //     position: absolute;
    //     z-index: 1;
    //     top: 210px;
    // }
 
 }
